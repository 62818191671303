import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import styled from "styled-components";

import { Container, Wrapper, Row, Box } from "../components/util";
import Layout from "../components/layout";
import Seo from "../components/seo";
import { CustomerCard, CallToAction } from "../components/site";

const CaseStudies = (props) => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulCustomerStory(sort: { fields: priority, order: ASC }) {
        edges {
          node {
            name
            url
            priority
            industry
            heroQuote
            banner {
              gatsbyImageData(layout: FULL_WIDTH, quality: 100)
            }
            logo {
              gatsbyImageData(layout: FULL_WIDTH, quality: 100)
            }
          }
        }
      }
    }
  `);

  return (
    <Layout>
      <Seo
        title="Customer Stories | Payroll Reviews | PayHero Customers"
        description="Meet some NZ companies doing business better. Hear what these clever companies have to say about how PayHero is helping their business."
        pathname={props.location.pathname}
      />
      <Container>
        <Wrapper stackGap={80}>
          <div className="-textCenter">
            <h1>Meet some NZ companies doing business better</h1>
            <h4>
              Hear what these clever companies have to say about how PayHero is
              helping their business.
            </h4>
          </div>
          <Row stackGap={30} justify="flex-start" isWrap>
            {data.allContentfulCustomerStory.edges.map((edge, i) => {
              return (
                <Box size={50} offset={30 / 2}>
                  <CustomerCard
                    key={i}
                    slug={edge.node.url}
                    banner={edge.node.banner.gatsbyImageData}
                    logo={edge.node.logo.gatsbyImageData}
                    quote={edge.node.heroQuote}
                    industry={edge.node.industry}
                    company={edge.node.name}
                  />
                </Box>
              );
            })}
          </Row>
          <h4 className="-textCenter" css={{ marginBottom: "25px" }}>
            Do you have a PayHero story to tell? Get in touch by emailing{" "}
            <a href="mailto:hello@payhero.co.nz">hello@payhero.co.nz</a>.
          </h4>
        </Wrapper>
      </Container>
      <CallToAction />
    </Layout>
  );
};

export default CaseStudies;
